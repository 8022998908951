import {z} from 'zod'
import {NodeTypeEnum} from './NodeTypeEnum'
import {DataParamsSchema, OperatorsSchema} from './paramFilters/paramFilters'

// MARK: - Entry condition step

const StepEntryConditionConfigSchema = z.array(
  z.object({
    // category: CategorySchema, we won't add it here because it is not yet saved on the backend.
    field: DataParamsSchema,
    operator: OperatorsSchema,
    value: z.union([z.number(), z.string()]).nullable(),
  })
)

export type EntryConditionConfig = z.infer<typeof StepEntryConditionConfigSchema>

export const StepEntryConditionSchema = z.object({
  id: z.string(),
  type: z.enum([NodeTypeEnum.entryCondition]),
  reentryDays: z.union([z.number(), z.null()]),
  next: z.string(),
  config: StepEntryConditionConfigSchema,
})

export type EntryCondition = z.infer<typeof StepEntryConditionSchema>

// MARK: - Steps

const StepSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(NodeTypeEnum),
  next: z.string().optional(),
  config: z.any().optional(),
  haveWarnings: z.boolean().optional(),
})
export type Step = z.infer<typeof StepSchema>
export const StepsSchema = z.record(z.string(), StepSchema)
export type Steps = z.infer<typeof StepsSchema>

// MARK: - Wait step

const StepWaitConfigSchema = z.object({value: z.union([z.number(), z.null()])})
export type StepWaitConfig = z.infer<typeof StepWaitConfigSchema>

export const StepWaitSchema = StepSchema.extend({
  type: z.enum([NodeTypeEnum.wait]),
  config: StepWaitConfigSchema,
})
export type StepWait = z.infer<typeof StepWaitSchema>

// MARK: - Send notification steps

const StepNotificationConfigBaseSchema = z.object({
  to: z.string(),
  message: z.string(),
})

// MARK: Send SMS step

const StepSendSMSConfigSchema = StepNotificationConfigBaseSchema
export const StepSendSMSSchema = StepSchema.extend({
  type: z.enum([NodeTypeEnum.sendSMS]),
  config: StepSendSMSConfigSchema,
})

export type StepSendSMSConfig = z.infer<typeof StepSendSMSConfigSchema>
export type StepSendSMS = z.infer<typeof StepSendSMSSchema>

// MARK: Send WhatsApp step

const StepSendWhatsAppConfigSchema = StepNotificationConfigBaseSchema.extend({
  template: z.string(),
})
export const StepSendWhatsAppSchema = StepSchema.extend({
  type: z.enum([NodeTypeEnum.sendWhatsApp]),
  config: StepSendWhatsAppConfigSchema,
})

export type StepSendWhatsAppConfig = z.infer<typeof StepSendWhatsAppConfigSchema>
export type StepSendWhatsApp = z.infer<typeof StepSendWhatsAppSchema>

// MARK: Send Push Notification step

const StepPushNotificationConfigSchema = StepNotificationConfigBaseSchema.extend({
  subject: z.string(),
  imageId: z.string().optional(),
  landingPageId: z.string().optional(),
})
export const StepSendPushNotificationSchema = StepSchema.extend({
  type: z.enum([NodeTypeEnum.sendPush]),
  config: StepPushNotificationConfigSchema,
})

export type StepSendPushNotificationConfig = z.infer<typeof StepPushNotificationConfigSchema>
export type StepSendPushNotification = z.infer<typeof StepSendPushNotificationSchema>

// MARK: - RequiresIntervention step

const StepRequiresInterventionConfigSchema = z.object({}).nullable().optional()

export const StepRequiresInterventionSchema = StepSchema.extend({
  type: z.enum([NodeTypeEnum.requiresIntervention]),
  config: StepRequiresInterventionConfigSchema,
})
export type StepRequiresIntervention = z.infer<typeof StepRequiresInterventionSchema>
