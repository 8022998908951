import {
  BooleanOperatorsSchema,
  BooleanParamsSchema,
  CategorySchema,
  CategoryTraitsMapping,
  CategoryType,
  ComparativeOperatorsSchema,
  DataParams,
  DateOperatorsSchema,
  DateParamsSchema,
  EnumOperatorsSchema,
  EnumParamsSchema,
  IntegerParamsSchema,
  ListOperatorsSchema,
  OperatorTypeSchema,
  Operators,
  OperatorsSchema,
  OperatorsType,
  StringOperatorsSchema,
  StringParamsSchema,
  compaignCategorySchema,
  loginActivityCategorySchema,
  orderCategorySchema,
  pointsCategorySchema,
  promotionCategorySchema,
  registrationCategorySchema,
  storeDemographyCategorySchema,
  targetCategorySchema,
} from '../types/paramFilters/paramFilters'

export const MAX_INPUT_VALUE_LENGTH = 200000
export const MAX_MESSAGE_LENGTH = 140
export const MAX_SUBJECT_LENGTH = 50

export const TEXT_TAG_ACTIVATION_CHAR = '$'
export const textTagSuggestions = [
  'AmountPointsToExpire',
  'CutOffTime',
  'DaysSinceLastLogin',
  'StoreCountry',
  'StoreLastLogonDate',
  'StoreName',
  'StoreOwnerEmailAddress',
  'StoreOwnerFirstName',
  'StoreOwnerLastName',
  'StoreOwnerName',
  'Today',
]

export const traitEnumOptions: Record<string, string[]> = {
  storeStatusName: ['active', 'inactive', 'deleted', 'pending'],
  portalTypeValue: ['ce', 'do', 'cedo'],
}

type OperatorsItem = {
  value: Operators
  displayValue: string
}

export const operators = (): OperatorsItem[] => {
  return [...new Set(OperatorsSchema.options)].map(value => {
    return {value, displayValue: `TRAIT_OPERATOR.${value}`}
  })
}

export type ParamsItem = {
  value: DataParams
  displayValue: string
  type: OperatorsType
}

export const categoryParams = CategorySchema.options
  .filter(value => value !== '')
  .map(value => {
    return {value, displayValue: `CATEGORY_PARAM.${value}`}
  })

export const categoryTraits: CategoryTraitsMapping = {
  points: pointsCategorySchema.options,
  registration: registrationCategorySchema.options,
  loginActivity: loginActivityCategorySchema.options,
  storeDemography: storeDemographyCategorySchema.options,
  progmotion: promotionCategorySchema.options,
  order: orderCategorySchema.options,
  target: targetCategorySchema.options,
  campaign: compaignCategorySchema.options,
}

export const traitToCategoryMap = (() => {
  const map = new Map<string, keyof CategoryTraitsMapping>()
  for (const [category, traits] of Object.entries(categoryTraits)) {
    traits.forEach(trait => {
      map.set(trait, category as keyof CategoryTraitsMapping)
    })
  }
  return map
})()

export const getTraitOptions = (category: CategoryType): ParamsItem[] => {
  const list: string[] = category === '' ? [] : categoryTraits[category]
  const booleanParams = BooleanParamsSchema.options
    .filter(value => {
      return list.includes(value)
    })
    .map(value => {
      return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.boolean}
    })

  const integerParams = IntegerParamsSchema.options
    .filter(value => {
      return list.includes(value)
    })
    .map(value => {
      return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.integer}
    })

  const stringParams = StringParamsSchema.options
    .filter(value => {
      return list.includes(value)
    })
    .map(value => {
      return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.string}
    })

  const dateParams = DateParamsSchema.options
    .filter(value => {
      return list.includes(value)
    })
    .map(value => {
      return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.date}
    })

  const enumParams = EnumParamsSchema.options
    .filter(value => {
      return list.includes(value)
    })
    .map(value => {
      return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.enum}
    })

  return [...booleanParams, ...integerParams, ...stringParams, ...dateParams, ...enumParams].sort((a, b) =>
    a.displayValue.localeCompare(b.displayValue)
  )
}

export const params = (): ParamsItem[] => {
  const booleanParams = BooleanParamsSchema.options.map(value => {
    return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.boolean}
  })

  const integerParams = IntegerParamsSchema.options.map(value => {
    return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.integer}
  })

  const stringParams = StringParamsSchema.options.map(value => {
    return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.string}
  })

  const dateParams = DateParamsSchema.options.map(value => {
    return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.date}
  })

  const enumParams = EnumParamsSchema.options.map(value => {
    return {value, displayValue: `TRAIT_PARAM.${value}`, type: OperatorTypeSchema.Values.enum}
  })

  return [...booleanParams, ...integerParams, ...stringParams, ...dateParams, ...enumParams]
}

export const getParamByValue = (value: DataParams) => {
  return params().find(param => param.value === value)!
}

export const isOperatorInteger = (value: Operators) => {
  return isOperatorNumber(value) || isComparative(value)
}

export const isOperatorString = (value: Operators) => {
  const allowed: string[] = StringOperatorsSchema.options
  return allowed.includes(value)
}

export const isOperatorBoolean = (value: Operators) => {
  const allowed: string[] = BooleanOperatorsSchema.options
  return allowed.includes(value)
}

export const isComparative = (value: Operators) => {
  const allowed: string[] = ComparativeOperatorsSchema.options
  return allowed.includes(value)
}

export const isOperatorList = (value: Operators) => {
  const allowed: string[] = ListOperatorsSchema.options
  return allowed.includes(value)
}

export const isOperatorDate = (value: Operators) => {
  const allowed: string[] = DateOperatorsSchema.options
  return allowed.includes(value)
}

export const isOperatorEnum = (value: Operators) => {
  const allowed: string[] = EnumOperatorsSchema.options
  return allowed.includes(value)
}

export const isOperatorNumber = (value: Operators) => {
  return !isOperatorBoolean(value) && !isOperatorList(value) && !isComparative(value)
}
